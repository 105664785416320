<template>
  <FormWrapper
    :formTitle="translations('InputEmailWillSendLink')"
    :v="$v.form"
    :formButton="translations('Submit')"
    :successMessage="translations('NewPasswordRequestSent')"
    :formCallback="doNewPasswordRequest"
  >
    <EmailField
      v-model="form.email"
      :fieldLabel="translations('EnterYourEmail')"
      :v="$v.form.email"
    />
  </FormWrapper>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FormWrapper from "./FormWrapper";
import EmailField from "./FieldComponents/EmailField";
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "ForgotPasswordForm",
  data: () => {
    return {
      form: {
        email: "",
      },
      wrapperLoaded: false,
    };
  },
  validations: {
    form: {
      email: { email, required },
    },
  },
  components: { FormWrapper, EmailField },
  computed: {
    ...mapGetters(["translations"]),
  },
  methods: {
    ...mapActions(["newPasswordRequest"]),
    async doNewPasswordRequest(recaptchaToken) {
      console.log("начало запроса нового пароля");
      await this.newPasswordRequest({
        email: this.form.email,
        token: recaptchaToken,
      });
    },
  },
  mounted() {},
};
</script>