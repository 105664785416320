<template>
  <div class="contents-area login-container">
    <div
      class="login-container-login-pic"
      :style="
        'background-image: linear-gradient(0deg, rgba(92, 107, 171, 0.3), rgba(92, 107, 171, 0.3)), url(//' +
        serverLink +
        '/upload/image/login-img.jpg); background-position: ' +
        picPosition
      "
    ></div>
    <div class="login-container-form-area">
      <div class="login-register-cont" v-if="isSettingsLoaded">
        <div class="login-menu">
          <a ref="loginLink" href="#" class="active">{{
            translations("ForgotPassword")
          }}</a>
          <div
            class="underline"
            :style="
              'left:' +
              underlinePosition.x +
              'px; width:' +
              underlinePosition.width +
              'px;'
            "
          ></div>
        </div>
        <div class="forms-container">
          <div class="login-part">
            <ForgotPasswordForm />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import { mapGetters } from "vuex";
import ForgotPasswordForm from "@/components/app/forms/ForgotPasswordForm";

export default {
  name: "Login",
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
      workingType: "login",
      underlinePosition: { x: 0, width: 0 },
    };
  },
  metaInfo() {
    return {
      title: this.translations("ForgotPassword"),
    };
  },
  components: { ForgotPasswordForm },
  computed: {
    ...mapGetters(["translations", "listSettings", "isSettingsLoaded"]),
    picPosition() {
      return "left center";
    },
  },
  methods: {},
  mounted() {
    setTimeout(() => {
      this.workingType = "login";
      this.underlinePosition = {
        x: this.$refs.loginLink.offsetLeft,
        width: this.$refs.loginLink.clientWidth,
      };
    }, 500);
  },
};
</script>