var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contents-area login-container"},[_c('div',{staticClass:"login-container-login-pic",style:('background-image: linear-gradient(0deg, rgba(92, 107, 171, 0.3), rgba(92, 107, 171, 0.3)), url(//' +
      _vm.serverLink +
      '/upload/image/login-img.jpg); background-position: ' +
      _vm.picPosition)}),_c('div',{staticClass:"login-container-form-area"},[(_vm.isSettingsLoaded)?_c('div',{staticClass:"login-register-cont"},[_c('div',{staticClass:"login-menu"},[_c('a',{ref:"loginLink",staticClass:"active",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.translations("ForgotPassword")))]),_c('div',{staticClass:"underline",style:('left:' +
            _vm.underlinePosition.x +
            'px; width:' +
            _vm.underlinePosition.width +
            'px;')})]),_c('div',{staticClass:"forms-container"},[_c('div',{staticClass:"login-part"},[_c('ForgotPasswordForm')],1)])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }